//Import
import i18n from '../i18n'; // Make sure to import your i18n instance

// Variables from environment
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const API_URL = process.env.REACT_APP_API_URL;
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;


// Images
export const default_image = `./images/placeholder_house.jpeg`;
export const house_image = default_image;
export const land_image = `./images/placeholder_land.jpeg`;
export const apartment_image = `./images/placeholder_apartment.jpeg`;
export const DEFAULT_CARD_COUNT = 40;


// Updated default image path
export const DEFAULT_IMAGE = default_image;
const DLA = `./images/cities/douala.jpg`;
const ABJ = `./images/cities/abidjan.jpg`;
const DKR = `./images/cities/dakar.jpg`;
const CTN = `./images/cities/cotonou.jpg`;
const ACC = `./images/cities/accra.webp`;
const BMK = `./images/cities/bamako.jpg`;


// sqft and m2
// Conversion constants
const SQFT_TO_M2 = 0.092903;
const M2_TO_SQFT = 10.7639;
const M2_TO_HECTARES = 0.0001;
const M2_TO_ACRES = 0.000247105;
const HECTARE_THRESHOLD = 10000; // 1 hectare = 10,000 m²
const ACRE_THRESHOLD = 4046.86; // 1 acre = 4046.86 m²



// Format price to locale string
export const formatPrice = (price, countryCode) => {
  const countryData = Object.values(countries).find(c => c.countryCode === countryCode);
  const currencySymbol = countryData ? countryData.currencySymbol : '$';

  let formattedPrice;
  if (price >= 1000000000) {
    const billions = price / 1000000000;
    formattedPrice = Number.isInteger(billions) ? billions.toFixed(0) : billions.toFixed(1);
    formattedPrice += 'B';
  } else if (price >= 1000000) {
    const millions = price / 1000000;
    formattedPrice = Number.isInteger(millions) ? millions.toFixed(0) : millions.toFixed(1);
    formattedPrice += 'M';
  } else if (price >= 1000) {
    formattedPrice = (price / 1000).toFixed(0) + 'K';
  } else {
    formattedPrice = price.toFixed(0);
  }

  // Replace decimal point with comma for French
  if (i18n.language === 'fr') {
    formattedPrice = formattedPrice.replace('.', ',');
  }

  return `${currencySymbol} ${formattedPrice}`;
};


// Convert sqft to m2
export const sqftToM2 = (sqft) => {
  return Math.round(sqft * SQFT_TO_M2);
};

// Convert m2 to sqft 
export const m2ToSqft = (m2) => {
  return Math.round(m2 * M2_TO_SQFT);
};

// Convert m2 to hectares
export const m2ToHectares = (m2) => {
  return m2 * M2_TO_HECTARES;
};

// Convert m2 to acres
export const m2ToAcres = (m2) => {
  return m2 * M2_TO_ACRES;
};

// Format square footage based on language and size
export const formatSqft = (m2Value, language) => {
  if (!m2Value) return '0';

  if (language === 'fr') {
    // French format
    if (m2Value >= HECTARE_THRESHOLD) {
      // Convert to hectares if >= 1 hectare
      const hectares = m2ToHectares(m2Value);
      // Format with 2 decimal places if less than 10 hectares, otherwise as integer
      const formattedValue = hectares < 10 
        ? hectares.toFixed(2).replace('.', ',')
        : Math.round(hectares).toLocaleString('fr-FR');
      return `${formattedValue} ha`;
    } else {
      // Keep as square meters
      return `${Math.round(m2Value).toLocaleString('fr-FR')} m²`;
    }
  } else {
    // English format
    const sqftValue = m2ToSqft(m2Value);
    if (sqftValue >= ACRE_THRESHOLD * M2_TO_SQFT) {
      // Convert to acres if >= 1 acre
      const acres = m2ToAcres(m2Value);
      // Format with 2 decimal places if less than 10 acres, otherwise as integer
      const formattedValue = acres < 10 
        ? acres.toFixed(2)
        : Math.round(acres).toLocaleString();
      return `${formattedValue} acres`;
    } else {
      // Keep as square feet
      return `${Math.round(sqftValue).toLocaleString()} sqft`;
    }
  }
};

// Calculate price per square foot/meter
export const calculatePricePerSqft = (price, m2Value, language, countryCode) => {
  if (!price || !m2Value || m2Value === 0) return 'N/A';

  if (language === 'fr') {
    // Calculate price per m²
    const pricePerM2 = price / m2Value;
    const formattedPrice = formatPrice(pricePerM2, countryCode);
    return `${formattedPrice}/m²`;
  } else {
    // Calculate price per sqft
    const sqftValue = m2ToSqft(m2Value);
    const pricePerSqft = price / sqftValue;
    const formattedPrice = formatPrice(pricePerSqft, countryCode);
    return `${formattedPrice}/sqft`;
  }
};
// In utils/helpers.js

// Calculate total price from price per unit
export const calculateTotalPrice = (pricePerUnit, size, isPricePerM2, language) => {
  if (!pricePerUnit || !size) return 0;
  
  const numericPrice = Number(pricePerUnit);
  const numericSize = Number(size);
  
  if (isNaN(numericPrice) || isNaN(numericSize)) return 0;
  
  // For English, input is in sqft
  if (language === 'en') {
    if (isPricePerM2) {
      // Convert sqft price to m² price
      const pricePerM2 = numericPrice * M2_TO_SQFT;
      return Math.round(pricePerM2 * numericSize);
    } else {
      // Price is already per sqft
      return Math.round(numericPrice * numericSize);
    }
  } else {
    // For French, input is in m²
    if (isPricePerM2) {
      // Price is already per m²
      return Math.round(numericPrice * numericSize);
    } else {
      // Convert m² price to sqft price
      const pricePerSqft = numericPrice * SQFT_TO_M2;
      return Math.round(pricePerSqft * numericSize);
    }
  }
};

// Calculate price per unit from total price
export const calculatePricePerUnit = (totalPrice, size, isPricePerM2, language) => {
  if (!totalPrice || !size) return 0;
  
  const numericPrice = Number(totalPrice);
  const numericSize = Number(size);
  
  if (isNaN(numericPrice) || isNaN(numericSize)) return 0;
  
  // For English, output in sqft
  if (language === 'en') {
    if (isPricePerM2) {
      // Calculate price per m² then convert to sqft
      const pricePerM2 = numericPrice / numericSize;
      return Math.round(pricePerM2 / M2_TO_SQFT);
    } else {
      // Calculate price per sqft directly
      return Math.round(numericPrice / numericSize);
    }
  } else {
    // For French, output in m²
    if (isPricePerM2) {
      // Calculate price per m² directly
      return Math.round(numericPrice / numericSize);
    } else {
      // Calculate price per sqft then convert to m²
      const pricePerSqft = numericPrice / numericSize;
      return Math.round(pricePerSqft / SQFT_TO_M2);
    }
  }
};

// Truncate long text
export const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  return text.substr(0, maxLength) + '...';
};

export const capitalizeFirstLetter = (str) => {
  // Check if the input is null, undefined, or not a string
  if (str == null || typeof str !== 'string') {
    return '';
  }

  // Trim the string and check if it's empty
  str = str.trim();
  if (str === '') {
    return '';
  }

  // Capitalize the first letter and concatenate with the rest of the string
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

// Format date to local string
export const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

// First, add a helper function to format the date (you can add this at the top of the file)
export const formatDatePost = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toISOString().split('T')[0]; // This will give you YYYY-MM-DD format
};

// Generate a slug from a string
export const generateSlug = (str) => {
  return str
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');
};

// Parse query parameters from URL
export const parseQueryParams = (queryString) => {
  const params = new URLSearchParams(queryString);
  const result = {};
  for (const [key, value] of params) {
    result[key] = value;
  }
  return result;
};

export const countries = {
  'Cameroon': {
    countryCode: 'CM',
    phoneCountryCode: 237,
    currency: 'XAF',
    currencySymbol: 'CFA',
    picture: DLA,
    cities: {
      Douala: { lat: 4.0511, lng: 9.7679 },
      Yaounde: { lat: 3.8480, lng: 11.5021 },
      Baffoussam: { lat: 5.4768, lng: 10.4214 },
      Garoua: { lat: 9.3017, lng: 13.3921 },
      Maroua: { lat: 10.5910, lng: 14.3158 },
      Bamenda: { lat: 5.9631, lng: 10.1591 },
      Limbe: { lat: 4.0215, lng: 9.1954 },
      Kribi: { lat: 2.9406, lng: 9.9097 }
    }
  },
  'Ivory Coast': {
    countryCode: 'CI',
    phoneCountryCode: 225,
    currency: 'XOF',
    currencySymbol: 'CFA',
    picture: ABJ,
    cities: {
      Abidjan: { lat: 5.3600, lng: -4.0083 },
      Yamoussoukro: { lat: 6.8276, lng: -5.2893 }
    }
  },
  'Ghana': {
    countryCode: 'GH',
    phoneCountryCode: 233,
    currency: 'GHS',
    currencySymbol: '₵',
    picture: ACC,
    cities: {
      Accra: { lat: 5.6037, lng: -0.1870 },
      Kumasi: { lat: 6.6885, lng: -1.6244 }
    }
  },
  'Mali': {
    countryCode: 'ML',
    phoneCountryCode: 223,
    currency: 'XOF',
    currencySymbol: 'CFA',
    picture: BMK,
    cities: {
      Bamako: { lat: 12.6392, lng: -8.0029 },
      Sikasso: { lat: 11.3170, lng: -5.6669 }
    }
  },
  'Senegal': {
    countryCode: 'SN',
    phoneCountryCode: 221,
    currency: 'XOF',
    currencySymbol: 'CFA',
    picture: DKR,
    cities: {
      Dakar: { lat: 14.7167, lng: -17.4677 },
      'Saint-Louis': { lat: 16.0179, lng: -16.4896 }
    }
  },
  'Benin': {
    countryCode: 'BJ',
    phoneCountryCode: 229,
    currency: 'XOF',
    currencySymbol: 'CFA',
    picture: CTN,
    cities: {
      Cotonou: { lat: 6.3703, lng: 2.3912 },
      'Porto-Novo': { lat: 6.4969, lng: 2.6283 }
    }
  }
};

export const getCoordinates = (country, city) => {
  const normalizeString = (str) => str.trim().toLowerCase();
  const normalizedCountry = normalizeString(country);
  const normalizedCity = normalizeString(city);

  for (const [countryName, countryData] of Object.entries(countries)) {
    if (normalizeString(countryName) === normalizedCountry) {
      for (const [cityName, coordinates] of Object.entries(countryData.cities)) {
        if (normalizeString(cityName) === normalizedCity) {
          return coordinates;
        }
      }
    }
  }
  
  console.error(`Coordinates not found for ${country}, ${city}. Using default.`);
  return { lat: 9.1450, lng: 18.4275 }; // Default coordinate (center of Africa)
};

export const getCityCountryOptions = () => {
  const options = [];
  for (const [countryName, countryData] of Object.entries(countries)) {
    for (const cityName of Object.keys(countryData.cities)) {
      // Ensure consistent formatting of city, country pairs
      options.push(`${cityName}, ${countryName}`);
    }
  }
  return options.sort();
};

export const getCityCountry = async (lat, lng) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAPS_API_KEY}`;
  
  try {
    const response = await fetch(url);
    const data = await response.json();
    
    if (data.status !== 'OK') {
      throw new Error('Geocoding API request failed');
    }

    let city = '';
    let country = '';

    for (const result of data.results) {
      for (const component of result.address_components) {
        if (component.types.includes('locality')) {
          city = component.long_name;
        }
        if (component.types.includes('country')) {
          country = component.long_name;
        }
      }
      if (city && country) break;
    }

    return { city, country };
  } catch (error) {
    console.error('Error in reverse geocoding:', error);
    return { city: '', country: '' };
  }
};

export const maskEmail = (email) => {
  if (!email) return '';
  
  // Check if it ends with "email.com"
  if (email.endsWith('@email.com')) {
    return ''; // Return empty string for emails ending with @email.com
  }

  // Return the original email for all other cases
  return email;
};